import React, { useEffect, useRef } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { isValid } from 'postcode';
import SEO from '../components/SEO';
import { useMemo } from 'react';
import SliderSection from '../components/CommonSections/SliderSection';
import imageStepsIcon from '../assets/images/icons/other/template-page-steps-icon.png';
import FAQSection from '../components/FAQSection';
import DownloadAppSection from '../components/CommonSections/DownloadAppSection';
import SectionWrapper from '../components/CommonSections/SectionWrapper';
import CustomHeadingSection from '../components/CommonSections/CustomHeadingSection';
import HeaderLeftContent from '../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import { SecondaryDescription, SecondaryTitle } from '../components/Typography';
import Heading from '../components/Heading';
import Button from '../components/Button';
import { useForm } from 'react-hook-form';
import RegisteredInput from '../components/CommonSections/formElements/RegisteredInput';
import InputWithValidation from '../components/CommonSections/formElements/InputWithValidation';
import Dropdown from '../components/CommonSections/formElements/Dropdown';
import { useState } from 'react';
import { useCallback } from 'react';
import {
  SPRIVE_API_BASE_URL,
  SPRIVE_API_KEY,
  DESTINATION_ECHO,
  HOVER_COLOR,
  FULL_NAME_REGEX,
} from '../constants';
import useWindow from '../hooks/useWindow';
import imageTestimonialsIndi1x from '../assets/images/illustrations/indi-testimonials1x.png';
import imageTestimonialsIndi2x from '../assets/images/illustrations/indi-testimonials2x.png';
import imageTestimonialsJenny1x from '../assets/images/illustrations/jenny-testimonials1x.png';
import imageTestimonialsJenny2x from '../assets/images/illustrations/jenny-testimonials2x.png';
import imageTestimonialsBrijesh1x from '../assets/images/illustrations/brijesh-testimonials1x.png';
import imageTestimonialsBrijesh2x from '../assets/images/illustrations/brijesh-testimonials2x.png';
import imageTestimonialsBella1x from '../assets/images/illustrations/bella-testimonials1x.png';
import imageTestimonialsBella2x from '../assets/images/illustrations/bella-testimonials2x.png';
import ContactWidget from '../components/ContactWidget';
import ModalsFlow from '../components/Modals/ModalsFlow';
import BasicModal from '../components/Modals/BasicModal';
import DownloadBonusModal from '../components/Modals/DownloadBonusModal';
import useSSR from '../hooks/useSSR';
import useSliderHeight from '../hooks/useSliderHeight';
import { useContext } from 'react';
import { LayoutContext } from '../components/Layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const FAQItems = [
  {
    title: 'How does Sprive help with remortgaging?',
    description: (
      <>
        If you’re using the Sprive app to mortgage overpayments, then you’ll unlock the ability for
        our smart technology to compare your mortgage against the market every day and see live
        personalised deals within the app. At the right time, we’ll connect you to a mortgage expert
        who will help you secure the right deal for you for free. It’s a fast way to switch as we
        already know a lot about you and your mortgage. By switching via the app flow, we’ll
        seamlessly help you make mortgage overpayments on your new mortgage and be able to
        automatically take into account any early repayment charges associated with your new
        mortgage
        <br />
        <br />
        If you’re not a user of the app, we can still help you. Simply book an appointment with an
        expert via our website.
      </>
    ),
  },
  {
    title: 'What is Remortgaging?',
    description: (
      <>
        Remortgaging is the process of switching your current mortgage to a new lender or
        negotiating a new deal with your current lender. The main reasons for remortgaging are to
        save money on your mortgage payments, release equity from your property, or change the terms
        of your mortgage agreement. Read our{' '}
        <Link to="https://sprive.com/blog/20-reasons-to-remortgage-with-sprive/">blog</Link> here to
        learn more on the benefits of remortgaging with us as a Sprive app user.
      </>
    ),
  },
  {
    title: 'What is the difference between mortgage and remortgage?',
    description: (
      <>
        A mortgage is a loan you take out to buy a property, while a remortgage is when you switch
        your existing mortgage to a new one. When you first take out a mortgage, it is typically for
        a fixed term, usually between 2 and 5 years. After this initial period, you can either stay
        on the same mortgage deal or switch to a new deal by remortgaging.
      </>
    ),
  },
  {
    title: 'Is it cheaper to remortgage or get a new mortgage?',
    description: (
      <>
        Remortgaging can be cheaper than getting a new mortgage, depending on the interest rates and
        fees involved. When deciding between remortgaging or getting a new mortgage, consider the
        following factors:
        <br />
        <br />
        <ul>
          <li>Compare the interest rates and fees involved to determine which option is cheaper</li>
          <li>Consider the length of the mortgage term</li>
          <li>Look at any penalties for early repayment</li>
          <li>Think about your long-term financial goals</li>
        </ul>
        <br />
        Remember to shop around and compare deals to find the best option for your individual
        circumstances.
      </>
    ),
  },
  {
    title: 'Why should I remortgage?',
    description: (
      <>
        People remortgage for various reasons, including securing better interest rates across the
        mortgage market, accessing equity to fund home improvements, or switching to a different
        type of mortgage (for example, away from a fixed rate deal to one with the lender's standard
        variable rate). Additionally, it can be a way to consolidate debt into a single payment. For
        more information, read our blog{' '}
        <Link to="https://sprive.com/blog/when-should-you-switch-mortgage/">here</Link>.
      </>
    ),
  },
  {
    title: 'What are the key factors I need to consider when I remortgage?',
    description: (
      <>
        When remortgaging, consider factors like fees, interest rates, mortgage term length, and any
        penalties for early repayment. It's also important to think about your long-term financial
        goals and whether remortgaging aligns with them and the purpose behind it.
        <br />
        <br />
        For example, if you’re considering releasing equity from your home to finance a second
        property, it's essential to evaluate the investment potential of the money you release.
        Additionally, using the equity for home improvements could increase your property's value
        and yield a better return on investment.
        <br />
        <br />
        When releasing equity, it's crucial to consider the impact on your overall financial
        situation, including the potential for higher mortgage payments and longer repayment terms.
        Before deciding to remortgage, it's advisable to speak to an independent financial advisor
        to ensure that you're making an informed decision.
      </>
    ),
  },
  {
    title: 'What are the expenses associated with a remortgage?',
    description: (
      <>
        When you decide to remortgage your property, there are several fees associated with the
        process. Here are some of the common fees you may encounter:
        <br />
        <br />
        <ol>
          <li>
            Valuation fee: This is the cost of having your property valued by a surveyor to
            determine its current market value.
          </li>
          <li>
            Arrangement fee: This is the fee charged by your lender for setting up your new
            mortgage. It can be a flat fee or a percentage of your loan amount.
          </li>
          <li>
            Legal fees: You will need to hire a solicitor or conveyancer to handle the legal aspects
            of your remortgage.
          </li>
          <li>
            Early repayment charges: If you are remortgaging before the end of your current mortgage
            term, you may be charged an early repayment fee.
          </li>
          <li>
            Exit fee: Your current lender may charge you an exit fee for leaving their mortgage
          </li>
          <li>
            Broker fee: If you use a mortgage broker to find your new mortgage deal, they may charge
            a fee for their services.
          </li>
        </ol>
        <br />
        There may be additional costs involved and that’s why it's important to factor them into
        your budget. For more information, read our blog{' '}
        <Link to="https://sprive.com/blog/remortgaging-costs-understanding-the-expenses-involved/">
          here
        </Link>
        .
      </>
    ),
  },
  {
    title: 'Am I eligible for a remortgage?',
    description: (
      <>
        You’re typically eligible to remortgage 6 months before your existing deal expires. Once
        your deal expires, your interest rate interest rate will revert to the Standard Variable
        Rate (SVR) of the current mortgage lender. If you switch within your deal period, you’re
        likely be charged an early repayment charge.
        <br />
        <br />
        When considering a remortgage, your eligibility will depend on factors such as your credit
        score, income, and equity in your home. Lenders will also look at your existing mortgage and
        debts to ensure you can manage repayments. Eligibility criteria may vary among lenders, so
        it's important to compare offers to find the best deal for your situation.
      </>
    ),
  },
  {
    title: 'Should I remortgage with my current lender or a new mortgage lender?',
    description: (
      <>
        When it comes to deciding whether to remortgage with your current lender or switch to a new
        mortgage lender, there are benefits to both. At Sprive, we have access to mortgage experts
        who can help you with both. The benefits of sticking with your existing lender include:
        <br />
        <br />
        <ul>
          <li>
            <span>Interest Rates:</span> If you have an established relationship with your lender,
            they may offer you a more competitive interest rate than a new lender would.
          </li>
          <li>
            <span>Fees & Charges:</span> If you have a good payment history and a strong credit
            score, you may be able to negotiate with your current lender to waive or reduce certain
            fees, such as arrangement or valuation fees.
          </li>
          <li>
            <span>Simplified Application Process:</span> You may be able to avoid a credit check or
            a full affordability assessment, as your lender already has access to your payment
            history and financial information.
          </li>
        </ul>
        <br />
        On the other hand, benefits of choosing a new lender include:
        <br />
        <br />
        <ul>
          <li>
            <span>Interest Rates:</span> You may find better deals and potentially lower interest
            rates if you shop across multiple lenders to ensure that you're getting the best
            possible deal.
          </li>
          <li>
            <span>More Variety:</span> If you're looking for a particular type of mortgage product,
            such as a flexible mortgage or an interest-only mortgage, you may need to look beyond
            your current lender to browse mortgage deals and find the right product for you.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'What are the risks of remortgaging?',
    description: (
      <>
        Remortgaging can have its downsides, including:
        <br />
        <br />
        <ul>
          <li>
            Fees and costs involved: Remortgaging can be more expensive than expected due to fees
            and costs like arrangement fees, valuation fees, legal fees, and exit fees.
          </li>
          <li>
            Longer repayment term: Remortgaging to release equity or consolidate debt may extend the
            repayment term, resulting in paying more interest over the life of the mortgage.
          </li>
          <li>
            Risk of negative equity: If the value of your property decreases, you may end up in
            negative equity, making it difficult to remortgage or sell your property.
          </li>
          <li>
            Impact on credit score: Remortgaging can affect your credit score, making it harder to
            obtain credit in the future.
          </li>
          <li>
            Risk of repossession: If you use your property as security for the loan and can't keep
            up with repayments, you could be at risk of losing your home.
          </li>
        </ul>
        <br />
        It's important to consider the potential downsides and assess whether remortgaging is the
        best option for your financial situation.
      </>
    ),
  },
  {
    title: 'Do I pay less when I remortgage?',
    description: (
      <>
        Remortgaging can potentially result in lower monthly payments or a lower interest rate, but
        it depends on the specific deal you can secure.
        <br />
        <br />
        You may be able to save money by remortgaging to a new fixed or variable rate deal at a
        lower interest rate, but it's important to compare offers and consider the fees and costs
        involved depending on whether you’re sticking with your current lender or choosing a new
        lender.
        <br />
        <br />
        If you're remortgaging to release equity or consolidate debt, consider the impact of a
        longer repayment term and increased interest costs over the life of the mortgage.
      </>
    ),
  },
  {
    title: 'How does my credit score affect my remortgage?',
    description: (
      <>
        Your credit score will play a significant role in whether or not you are eligible for a
        remortgage, as well as the interest rate you are offered. Lenders will use your credit score
        to assess your risk as a borrower and determine whether or not to offer you a remortgage.
        <br />
        <br />
        However, if you have bad credit, you may find it challenging to remortgage. But it can
        actually help rebuild your credit score, as it can consolidate debts, provide better credit
        terms, and lower monthly payments over time. Having bad credit doesn't mean you cannot
        remortgage, and it's worth exploring your options to see if it could benefit your financial
        situation.
      </>
    ),
  },
];

const remortgageDropdown = [
  {
    value: 'Remortgage',
    id: 0,
  },
  {
    value: 'Purchase',
    id: 1,
  },
];

const mortgageLengthOptions = Array.from({ length: 40 }, (_, index) => ({
  id: index,
  num: (index + 1).toString(),
})); //1-40

function TemplatePage({ data, location }) {
  const templateData = useMemo(() => data?.allMarkdownRemark?.edges[0]?.node?.frontmatter, [data]);
  const destination = templateData?.destination;
  const isLenderSpecific = true;
  const isDestinationEcho = destination === DESTINATION_ECHO;

  const { setFooterContent } = useContext(LayoutContext);
  const { isDesktop, isMobile } = useWindow();
  const sliderHeight = useSliderHeight('611px', '198px', '234px');
  const isSSR = useSSR();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [contactNumberError, setContactNumberError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [dropdownError, setDropdownError] = useState([]);
  const [dropdownValue, setDropdownValue] = useState([
    {
      value: 'Remortgage',
      id: 0,
    },
    {
      value: 'Mortgage Length',
      id: -1,
    },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  //Declaration of References
  const mortgageAmountRef = useRef(null); // Create a ref for the Mortgage Amount input
  const propertyValueRef = useRef(null); // Create a ref for the Property Value input

  const onSubmit = async (data) => {
    if (!checkDropdown()) {
      return;
    }

    if (!contactNumberError) {
        // Show Loader on the Submit Button:
        setLoading(true);
        // API call:
        postUserQuery(data);
    }
  };

  const checkDropdown = useCallback(() => {
    const allErrorsIndexes = [];
    dropdownValue.forEach((item, index) => {
      // Mortgage length Dropdown
      if (item.id === -1) {
        allErrorsIndexes.push(index);
      }
    });

    // Required validation for Dropdown.
    if (allErrorsIndexes.length) {
      setDropdownError(allErrorsIndexes);
      return false;
    }

    return true;
  }, [dropdownValue]);

  const replaceCommas = useCallback((value) => {
    if (!value?.replace) return value;
    return parseInt(value.replace(/,/g, ''));
  });

  const postUserQuery = useCallback(
    async (formData) => {
      const requestBody = {
        mortgage_name: templateData.lender_name,
        post_code: formData['postcode'],
        mortgage_type: dropdownValue[0].value,
        mortgage_amount: replaceCommas(formData['mortgage_amount']),
        mortgage_length: Number(dropdownValue[1].value),
        property_value: replaceCommas(formData['property_value']),
        combined_income: replaceCommas(formData['combined_income']),
        destination: destination,
        is_lender_specific: isLenderSpecific,
        form_source_url: window?.location?.href || '',
        user: {
          email: formData['email'],
          name: formData['full_name'],
          contact_no: formData['contact_number'],
          mobile_no: formData['mobile_number'],
        },
      };

      const urlParams = new URLSearchParams(window?.location?.search);
      const affiliateCode = urlParams?.get('a');
      if (affiliateCode) Object.assign(requestBody, {affiliate_code: affiliateCode});
      else {
        const sourceName = localStorage.getItem('advertisingPartnerName') || 'organic';
        if (sourceName !== 'organic') Object.assign(requestBody, {affiliate_code: sourceName});
      }


      // todo catch block and resolve loader on CTA + Elegantly show error message.
      fetch(SPRIVE_API_BASE_URL + 'product-selector-sub-domain-search-preference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          api_key: SPRIVE_API_KEY,
        },
        body: JSON.stringify(requestBody),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            localStorage.setItem('uuid', data?.data?.uuid);

            localStorage.setItem(
              'mortgageData',
              JSON.stringify({
                ...requestBody,
                dropdownValue: dropdownValue[0].value,
              })
            );
            setLoading(false);

            const state = {
              useImage: true,
              useDropdown: true,
              isDestinationEcho,
              isLenderSpecific,
              customLinks: [
                {
                  title: 'Home',
                  leadsTo: '/',
                },
                {
                  title: 'View Deals',
                  leadsTo: null,
                  isActive: true,
                },
              ],
            };

            localStorage.setItem('dealsState', JSON.stringify(state));
            navigate('/view-deals');
          }
        });
    },
    [dropdownValue]
  );

  const updateDropdownValue = useCallback(
    (item, index, valueField) => {
      const newState = [...dropdownValue];

      newState[index] = {
        value: item[valueField],
        id: item?.id,
      };

      setDropdownValue(newState);
    },
    [dropdownValue]
  );

  const handleBonusClose = useCallback(() => {
    setIsBonusModalOpen(false);
    if (!isSSR) {
      localStorage.setItem('bonusModalState', 'close');
    }
  }, [isSSR]);

  useEffect(() => {
    if (isSSR) return;
    else if (localStorage.getItem('bonusModalState') !== 'open') return;

    setIsBonusModalOpen(true);
  }, [isSSR]);

  useEffect(() => {
    if (!templateData) return;

    setFooterContent(destination);
  }, [templateData]);

  const sliderItems = useMemo(
    () => [
      {
        image: isMobile ? imageTestimonialsJenny1x : imageTestimonialsJenny2x,
        description: `I recently remortgage with Sprive and I couldn't be happier
        with the experience. From start to finish, they made the process incredibly
        simple and efficient, surpassing my expectations in every way.`,
        person: 'Jenny Holden',
        personInfo: 'PR',
      },
      {
        image: isMobile ? imageTestimonialsIndi1x : imageTestimonialsIndi2x,
        description: `Thanks to Sprive I was able to both easily manage repayments,
        but also find great deals for remortgaging, which Sprive took the hassle out of too.`,
        person: 'Indi Rai',
        personInfo: 'Head of Marketing',
      },
      {
        image: isMobile ? imageTestimonialsBrijesh1x : imageTestimonialsBrijesh2x,
        description: `It’s coming to my time to remortgage. Sprive shows you live deals
        when you’re ready to remortgage, it's really hassle-free so you don’t need to
        fill out lots of forms and details because it has all of your mortgage details already.`,
        person: 'Brijesh Pujara',
        personInfo: 'Engineer',
      },
      {
        image: isMobile ? imageTestimonialsBella1x : imageTestimonialsBella2x,
        description: `The app is really user friendly and easy to use. It’s great to
        be able to chip away at my mortgage and see the impact that’s having on the overall length.`,
        person: 'Bella Melvin',
        personInfo: 'Solicitor',
      },
    ],
    [isMobile]
  );

  const isValidPrice = (value) => {
    if (Number(value) < 1) return false;
    return true;
  };

  const validateFullName = (fullNameField) => {
    const fullNameRegex = new RegExp(/^[a-zA-Z]([-']?[a-zA-Z]+){1,}( [a-zA-Z]([-']?[a-zA-Z]{1,}))+$/);
    const fullNameValue = fullNameField.target.value;

    if (fullNameRegex.test(fullNameValue)) {
      setFullNameError('');
      fullNameField.target.style.borderColor = null;
    } else {
      setFullNameError('Enter your full name');
      fullNameField.target.style.borderColor = HOVER_COLOR;
    }
  }

  const validateForLTV = (allFieldsData) => {
    const { mortgage_amount, property_value } = allFieldsData;
    let mortgageAmountField = mortgageAmountRef?.current?.base;
    let propertyValueField = propertyValueRef?.current?.base;
    const mortgageAmount = parseInt(mortgage_amount?.replace(/,/g, ''));
    const propertyValue = parseInt(property_value?.replace(/,/g, ''));

    if (
      mortgageAmount < 10000 ||
      propertyValue < 10000 ||
      (mortgageAmount / propertyValue) * 100 > 100
    ) {
      setFormError(
        'Mortage amount cannot be higher than property value. Also, both the values need to be at least £10,000'
      );
      mortgageAmountField.style.borderColor = propertyValueField.style.borderColor = HOVER_COLOR;
      return false;
    }
    if (!!formError) setFormError('');
    mortgageAmountField.style.borderColor = propertyValueField.style.borderColor = null;
    return true;
  };

  const convertToUpperCase = (event) => {
    event.target.value = event.target.value.toUpperCase();
  };

  const removeErrMessage = (event) => {
      setContactNumberError('');
  }

  const validateContactNumber = (event) => {
    const contact_number = event.target.value;

    const contactNumRegex = new RegExp(/^[+0]([1-9]+-?)\d{9,10}$|^([1-9])\d{9,11}$/);
    // Proceed for API call if front-end validations are cleared.
    if (contactNumRegex.test(contact_number)) {
        // Remove hyphen from the contact number if exists, before making the API call.
        const requestPayload = {contact_number: contact_number.replace(/-/g, "")};

        // API call.
        fetch(SPRIVE_API_BASE_URL + 'validate_contact_number', {
          method: 'POST',
          headers: {
            api_key: SPRIVE_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        })
          .then((data) => data.json())
          .then((response) => {
            // In case we haven't got the expected response from the backend, have the default result as true.
            const result = response?.data?.result ?? true;
            if (!result) {
                setContactNumberError('We don’t recognise this number. Are you sure it’s correct?');
                event.target.style.borderColor = HOVER_COLOR;
            }
          })
          .catch((err) => {})
    }
  };

  return <>
    <SEO title={templateData?.seo?.seo_title} description={templateData?.seo?.seo_description} />
    <div className="common-page template-page">
      <SectionWrapper>
        <div style={{ marginTop: '80px' }} />
        <CustomHeadingSection
          leftContent={
            <div className="template-page__heading-wrapper">
              <div className="template-page__heading">
                {isMobile !== undefined && (
                  <GatsbyImage
                    image={getImage(templateData.main_image)}
                    imgStyle={{
                      objectFit: 'contain',
                      objectPosition: isMobile ? 'center' : 'left',
                    }}
                  />
                )}
                <HeaderLeftContent
                  title="Get mortgage quotes and start comparing rates now"
                  description={
                    <>
                      Compare {templateData.lender_name} mortgage rates against the market today
                      for free.
                    </>
                  }
                />
              </div>
            </div>
          }
          rightContent={
            <div className="template-page__heading-steps">
              <div>
                <SecondaryTitle>How it works</SecondaryTitle>
                <img src={imageStepsIcon} />
              </div>
              <div className="template-page__heading-step-container">
                <SecondaryDescription>1. Complete the below simple form</SecondaryDescription>
                <SecondaryDescription>
                  2. We search the market for the latest mortgage deals
                </SecondaryDescription>
                <SecondaryDescription>
                  3. You can see the latest deals and you’ll get connected to an expert advisor
                  for free
                </SecondaryDescription>
              </div>
            </div>
          }
        />
        <div className="template-page__form-container">
          <Heading title="Tell us about your mortgage" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="template-page__form">
              <div className="template-page__form-inputs">
                <FormInput
                  register={register}
                  name="full_name"
                  placeholder="Full Name"
                  errors={errors}
                  // onInput={validateFullName}
                  validationOptions={{
                    required: true,
                    pattern: FULL_NAME_REGEX,
                  }}
                />
                <FormInput
                  register={register}
                  name="contact_number"
                  placeholder="Contact Number"
                  errors={errors}
                  onBlur={validateContactNumber}
                  onFocus={removeErrMessage}
                  validationOptions={{
                    required: true,
                    pattern: /^[+0]([1-9]+-?)\d{9,10}$|^([1-9])\d{9,11}$/g,
                  }}
                />
                <FormInput
                  register={register}
                  name="mobile_number"
                  placeholder="Mobile Number"
                  errors={errors}
                  validationOptions={{
                    required: false,
                    pattern: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g,
                  }}
                />
                <FormInput
                  register={register}
                  name="email"
                  placeholder="Email"
                  errors={errors}
                  validationOptions={{
                    required: true,
                    pattern: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  }}
                />
                <FormInput
                  register={register}
                  name="postcode"
                  placeholder="Postcode"
                  errors={errors}
                  // onInput={convertToUpperCase}
                  validationOptions={{
                    required: true,
                    validate: (value) => isValid(value),
                  }}
                />
                <Dropdown
                  dropdownOptions={remortgageDropdown}
                  dropdownValue={dropdownValue}
                  setDropdownValue={(item, index, valueField) =>
                    updateDropdownValue(item, index, valueField)
                  }
                  dropdownError={dropdownError}
                  setDropdownError={setDropdownError}
                  withoutIcon
                  dropdownIndex={0}
                  valueField="value"
                />
                <InputWithValidation
                  register={register}
                  name="mortgage_amount"
                  placeholder="Mortgage Amount"
                  errors={errors}
                  ref={mortgageAmountRef}
                  validationOptions={{
                    required: true,
                    validate: (value, allFieldsData) => validateForLTV(allFieldsData),
                  }}
                />
                <InputWithValidation
                  register={register}
                  name="property_value"
                  placeholder="Property Value"
                  errors={errors}
                  ref={propertyValueRef}
                  validationOptions={{
                    required: true,
                    validate: (value, allFieldsData) => validateForLTV(allFieldsData),
                  }}
                />
                {/*Mortgage Length Dropdown, Values: 1-40*/}
                <Dropdown
                  dropdownOptions={mortgageLengthOptions}
                  dropdownValue={dropdownValue}
                  setDropdownValue={(item, index, valueField) =>
                    updateDropdownValue(item, index, valueField)
                  }
                  dropdownError={dropdownError}
                  setDropdownError={setDropdownError}
                  withoutIcon
                  dropdownIndex={1}
                  valueField="num"
                />
                <InputWithValidation
                  register={register}
                  name="combined_income"
                  placeholder="Combined Income (£)"
                  errors={errors}
                  validationOptions={{
                    required: true,
                    // pattern: /^0*?[1-9]\d{0,13}$/,
                    validate: (value) => isValidPrice(value),
                  }}
                />
              </div>
              {fullNameError && <p style={{ color: 'red' }}>{fullNameError}</p>}
              {formError && <p style={{ color: 'red' }}>{formError}</p>}
              {contactNumberError && <p style={{ color: 'red' }}>{contactNumberError}</p>}
              <SecondaryDescription>
                By searching you accept our{' '}
                <a href="https://sprive.com/mortgagehelp-terms" target="__blank">
                  terms and conditions
                </a>{' '}
                and agree to our{' '}
                <a href="https://sprive.com/privacy" target="__blank">
                  privacy policy
                </a>
              </SecondaryDescription>
            </div>
            <Button
              buttonText="Get Deals"
              height="56px"
              submit
              handleClick={checkDropdown}
              loading={loading}
              fixedClassName={'button-main-subdomain'}
            />
          </form>
        </div>
      </SectionWrapper>
      <SliderSection sliderItems={sliderItems} customHeight={sliderHeight} />

      <div className='faq-section-title'>
        <FAQSection
          FAQItems={FAQItems}
          title={
            <>
              Have a question about remortgaging?
              {isDesktop ? <br /> : ' '}
              Check out our FAQ’s
            </>
          }
        />
      </div>
      <DownloadAppSection />
      {!isDestinationEcho && (
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true);
          }}
          title="Speak to a mortgage expert for FREE"
        />
      )}
      <ModalsFlow
        isFirstOpened={isContactModalOpen}
        setIsFirstOpened={(state) => setIsContactModalOpen(state)}
      />
      <BasicModal
        isOpen={isBonusModalOpen}
        closeHandler={handleBonusClose}
        isBottom={isMobile}
        style={{
          content: {
            height: isMobile ? '540px' : '650px',
          },
        }}
      >
        <DownloadBonusModal />
      </BasicModal>
    </div>
  </>;
}

const FormInput = ({ placeholder, register, errors, name, validationOptions, onInput, onBlur, onFocus }) => {
  const inputRegister = useMemo(
    () => register && register(name, validationOptions),
    [name, validationOptions, errors, onInput]
  );

  return (
    <RegisteredInput
      register={inputRegister}
      name={name}
      errors={errors}
      validationOptions={validationOptions}
      className="template-page__form-input"
      placeholder={placeholder}
      onInput={onInput}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export const PageQuery = graphql`
  query TemplatePage($id: String!) {
    allMarkdownRemark(filter: { id: { eq: $id } }) {
      edges {
        node {
          frontmatter {
            lender_name
            subdomain
            destination
            seo {
              seo_description
              seo_title
            }
            main_image {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplatePage;
